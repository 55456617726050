import React, { useState } from "react";
import {
    TextField,
    Button,
    Typography,
    Box,
    Alert,
    Container
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import axios from "axios";

const LoginPage = ({ onLoginSuccessful }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const response = await axios.post("/auth/login", {
                email,
                password
            });
            onLoginSuccessful(response.data.user, response.data.token);
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <Box
            sx={{
                minHeight: "100vh",
                bgcolor: "#f5f5f5",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}
        >
            <Container maxWidth="xs" sx={{ px: 2, textAlign: "center" }}>
                <Typography
                    variant="h4"
                    component="h1"
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                >
                    silverr AI
                </Typography>
                <Typography
                    variant="body1"
                    color="textSecondary"
                    sx={{ mb: 4 }}
                >
                    Bitte melden Sie sich an, um fortzufahren
                </Typography>

                <Box sx={{ mb: 3 }}>
                    <LockOutlinedIcon
                        fontSize="large"
                        color="primary"
                    />
                </Box>

                <form onSubmit={handleSubmit}>
                    <TextField
                        name="email"
                        label="E-Mail"
                        type="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoComplete="email"
                        InputLabelProps={{ shrink: true }}
                    />
                    <TextField
                        name="password"
                        label="Passwort"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        autoComplete="current-password"
                        InputLabelProps={{ shrink: true }}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2, py: 1.2 }}
                    >
                        Anmelden
                    </Button>
                </form>
                {error && (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        {error}
                    </Alert>
                )}
            </Container>
        </Box>
    );
};

export default LoginPage;
