import React, {useState} from 'react';
import {Box, Paper, Typography, IconButton, Tooltip, Switch} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import WarningIcon from '@mui/icons-material/Warning';
import { blue, amber, green, red } from '@mui/material/colors';
import {marked} from "marked";
import {ScheduleSend} from "@mui/icons-material";
import {formatTimeDistance} from "../utils";
import DiffMatchPatch from "diff-match-patch";
import TextDiffViewer from "./TextDiffViewer";

export const messageStatusIcon = {
    failed: <WarningIcon fontSize="small" sx={{ color: 'red' }} />,
    waiting: <ScheduleSend fontSize="small" sx={{ color: 'orange' }} />,
    sent: <DoneIcon fontSize="small" sx={{ color: 'text.secondary' }} />,
    delivered: <DoneAllIcon fontSize="small" sx={{ color: 'text.secondary' }} />,
    read: <DoneAllIcon fontSize="small" sx={{ color: blue[500] }} />
};

const MessageBubble = ({ message, handleSuggestedAnswerClick, pendingDerivation }) => {
    const [showSuggestionDiff, setShowSuggestionDiff] = useState(false);

    function getDiffText(from, to) {
        const dmp = new DiffMatchPatch();
        const diffs = dmp.diff_main(from, to);
        dmp.diff_cleanupSemantic(diffs)

        return diffs.map(([operation, text], index) => {
            let className = '';
            if (operation === DiffMatchPatch.DIFF_INSERT) {
                className = 'inserted'; // green for insertions
            } else if (operation === DiffMatchPatch.DIFF_DELETE) {
                className = 'deleted'; // red for deletions
            }

            return `<span class="${className}">${text}</span>`;
        }).join('');
    }

    return (
        <Box
            sx={{
                alignSelf: message.senderType === "assistant" ? 'flex-end' : 'flex-start',
                maxWidth: '80%',
            }}
        >
            <Paper
                elevation={2}
                sx={{
                    p: 1,
                    borderRadius: 3,
                    color: 'text.primary',
                    position: 'relative',
                }}
            >
                {message.senderType === "assistant" && (
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <Typography variant="subtitle2" color="textSecondary">
                            {`${message.author.firstname} ${message.author.lastname}` + (message.type !== "text" ? ` (${message.type})` : "")}
                        </Typography>
                        {message.basedOn && <Switch checked={showSuggestionDiff} onChange={e=>setShowSuggestionDiff(e.target.checked)} size="small" />}
                    </Box>
                )}

                {showSuggestionDiff ?
                    <TextDiffViewer oldText={message.basedOn.content} newText={message.content}></TextDiffViewer>
                    :
                    <Typography
                        variant="body1"
                        component="div"
                        dangerouslySetInnerHTML={{ __html: marked(message.content) }}
                        sx={{
                            color: message.type !== "text" && message.type !== "template" ? "red" : "inherit",
                        }}
                    />
                }

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: 2,
                            bgcolor: 'background.default',
                            px: 1,
                        }}
                    >
                        {message.reaction && (
                            <Typography variant="body" color="textSecondary">
                                {message.reaction}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 'auto' }}>
                        <Typography variant="caption" color="textSecondary" sx={{ mr: 1 }}>
                            {message.status==="failed" ? "Message could not be sent" : message.status==="waiting" ? "24h exceeded. Wait for client confirmation." : formatTimeDistance(new Date(message.timestamp), true)}
                        </Typography>
                        {message.senderType === "assistant" && (
                            <Box component="span">
                                {messageStatusIcon[message.status]}
                            </Box>
                        )}
                    </Box>
                </Box>

                {message.answerSuggestions.length > 0 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: -40,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {message.answerSuggestions.map(suggestion => (
                            <Tooltip key={suggestion._id} title={suggestion.model} arrow placement={"left"}>
                                <IconButton
                                    size="small"
                                    sx={{
                                        color:
                                            pendingDerivation === suggestion._id
                                                ? amber[600]
                                                : suggestion.rating === true
                                                    ? green[600]
                                                    : suggestion.rating === false
                                                        ? red[600]
                                                        : blue[600],
                                    }}
                                    onClick={() => handleSuggestedAnswerClick(message._id, suggestion)}
                                >
                                    <AutoAwesomeIcon />
                                </IconButton>
                            </Tooltip>
                        ))}
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default MessageBubble;