import { List, AppBar, Toolbar, IconButton, Typography, Box, Container, Button, Card, CardContent } from "@mui/material";
import ChatCard from "../components/ChatCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingPage from "./LoadingPage";
import { useNavigate } from "react-router-dom";
import React from "react";
import { subscribeNotifications } from "../helper/notifications";

const ChatListPage = ({ chats }) => {
    const navigate = useNavigate();

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <IconButton color="inherit" onClick={() => navigate("/")}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" sx={{ marginLeft: "10px" }}>
                        Chats
                    </Typography>
                </Toolbar>
            </AppBar>
            {chats ? (
                <Container>
                    <List>
                        {chats.map((chat) => (
                            <ChatCard key={chat._id} chat={chat} />
                        ))}
                    </List>
                    <Button onClick={subscribeNotifications} sx={{ mt: "2em" }}>
                        Subscribe to Push Notifications
                    </Button>
                </Container>
            ) : (
                <LoadingPage />
            )}
        </>
    );
};

export default ChatListPage;