import { format, isToday } from 'date-fns';

export function truncate(text, maxLength) {
    if (text.length <= maxLength) {
        return text;
    }
    return text.substring(0, maxLength)+" ...";
}

export function formatTimeDistance(timestamp, forceTime = false){
    if (isToday(timestamp)) {
        return format(timestamp, 'HH:mm'); // Show time (hour and minute) if it's today
    } else if (forceTime) {
        return format(timestamp, 'dd.MM.yyyy HH:mm'); // Show date (day, month, year) if it's not today
    } else {
        return format(timestamp, 'dd.MM.yyyy'); // Show date (day, month, year) if it's not today
    }
}