import React, { useEffect, useState } from 'react';
import DiffMatchPatch from 'diff-match-patch';
import {Typography} from "@mui/material";

const DiffViewer = ({ oldText, newText }) => {
    const [diffHtml, setDiffHtml] = useState('');

    useEffect(() => {
        const dmp = new DiffMatchPatch();
        const diffs = dmp.diff_main(oldText, newText);
        dmp.diff_cleanupSemantic(diffs);

        // Create custom HTML from diffs
        const html = diffs.map(([operation, text], index) => {
            let className = '';
            if (operation === DiffMatchPatch.DIFF_INSERT) {
                className = 'inserted'; // green for insertions
            } else if (operation === DiffMatchPatch.DIFF_DELETE) {
                className = 'deleted'; // red for deletions
            }

            return `<span class="${className}">${text}</span>`;
        }).join('');

        setDiffHtml(html);
    }, [oldText, newText]);

    return (
        <>
            <style>
                {`
                    .inserted {
                        background-color: #e6ffec;
                        color: green;
                    }
                    .deleted {
                        background-color: #ffdddd;
                        color: red;
                        text-decoration: line-through;
                    }
                `}
            </style>
            <Typography
                variant="body1"
                component="div"
                dangerouslySetInnerHTML={{ __html: diffHtml }}
                sx={{
                    py: 2
                }}
            />
        </>
    );
};

export default DiffViewer;
